import React, { useState, useEffect, useRef } from "react";
import { Transition } from "react-transition-group";
import Modal from "react-modal";
import { mediaQueryHOC } from "../../../adapters/helpers/Hooks";
import Block from "../../../adapters/helpers/Block";
import Image from "../../Image/Image";
import {
  StartGuideBlockConstants,
  Constants,
} from "../../../adapters/helpers/Constants";
import Slider from "react-slick";
import YoutubeVideo from "../../YoutubeVideo/YoutubeVideo";
import Icon from "../../Icon/Icon";
import { AnalyticsConstants } from "../../../adapters/helpers/ConstantsGA";
import {addRemainder} from "../../../adapters/helpers/Utils"

const StartGuideContentBlock = (props) => {
  const { isXSmall, isSmall } = props;
  const duration = 600;
  const block = new Block(props);
  const className = block.getFieldValue(StartGuideBlockConstants.className);
  const anchorId = block.getFieldValue(StartGuideBlockConstants.anchorId);
  const welcomeBlock = block.getFieldValue('welcomeBlock');
  const backgroundAsset = block.getFieldValue(
    StartGuideBlockConstants.backgroundAsset
  );
  const mobileBackgroundAsset = block.getFieldValue(
    StartGuideBlockConstants.mobileBackgroundAsset
  );
  const textAlignment = block.getFieldValue(
    StartGuideBlockConstants.textAlignment
  );
  const title = block.getFieldValue(StartGuideBlockConstants.title);
  const subTitle = block.getFieldValue(StartGuideBlockConstants.subTitle);
  const description = block.getFieldValue(StartGuideBlockConstants.description);
  const subdescription = block.getFieldValue(StartGuideBlockConstants.subdescription);
  const descriptionIcon = block.getFieldValue(
    StartGuideBlockConstants.descriptionIcon
  );
  const topicList = block.getFieldValue(StartGuideBlockConstants.topicList);
  const topicHeader = block.getFieldValue(StartGuideBlockConstants.topicHeader);
  const carouselCards = block.getFieldValue(
    StartGuideBlockConstants.carouselCards
  );
  const CTAInformation = block.getFieldValue(
    StartGuideBlockConstants.ctaInformation
  );
  const cards = block.getFieldValue(StartGuideBlockConstants.cards);
  const titleImage = block.getFieldValue(StartGuideBlockConstants.titleImage);
  const scrollContent = block.getFieldValue(
    StartGuideBlockConstants.scrollContent
  );
  const animationAsset = block.getFieldValue(
    StartGuideBlockConstants.animationAsset
  );
  const mobileAnimationAsset = block.getFieldValue(
    StartGuideBlockConstants.mobileAnimationAsset
  );
  const additionalAssetList = block.getFieldValue(
    StartGuideBlockConstants.additionalAssetList
  );
  const remainderCalender = block.getFieldValue(
    StartGuideBlockConstants.remainderCalender
  );
  const video = block.getFieldValue(StartGuideBlockConstants.video);
  const popupContent = block.getFieldValue(
    StartGuideBlockConstants.popupContent
  );

  const [selectedTopicList, setSelectedTopicList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderValue, setSliderValue] = useState({});
  const [showRemainderClasses, setShowRemainderClasses] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isGumBleedModalOpen, setIsGumBleedModalOpen] = useState( false );
  const [currentHoverIndex, setCurrentHoverIndex] = useState(10)

  const customStyles = {
    overlay: {
      boxShadow: "rgba(0, 0, 0, 0.5) 0 2px 4px",
      background: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
    },
    content: {
      position: "static",
      display: "flex",
      maxWidth: "1200px",
    },
  };

  const carouselSettings = {
    dots: true,
    arrows: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    infinite: true,
  };

  const customStylesVideoModal = {
    overlay: {
      position: "fixed",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      border: "none",
      top: "50%",
      right: "auto",
      left: "50%",
      bottom: "auto",
      padding: "0",
      marginLeft: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "100rem",
      width: "90%",
    },
  };

  const customStylesGumBleedModal = {
    overlay: {
      position: "fixed",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      border: "none",
      top: "50%",
      right: "50%",
      left: "auto",
      bottom: "auto",
      padding: "0",
      marginLeft: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "100rem",
      width: isXSmall || isSmall ? "90%" : "60%",
      "border-radius": "20px",
    },
  };

  const imageRef = useRef(null);
  const speed = 1.3;
  const [isStopped, setIsStopped] = useState(false);
  const endViewPort = ".start-guide-hero-banner";
  let currentZoom = 1;

  useEffect(() => {
    const endViewPortEle = document.querySelector(endViewPort);
    const handleScroll = () => {
      const scrollY = window.scrollY;
      let movement = scrollY * speed;
      const zoomFactor = 1 + scrollY / 2500;
      currentZoom = Math.min(zoomFactor, 1.3);

      const endRect = endViewPortEle.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let viewPortStatus = endRect.top < windowHeight && endRect.bottom > 0;
      if (imageRef && imageRef.current) {
        imageRef.current.style.transform = `translateY(${movement}px)`;
      }
      if (!viewPortStatus) {
        setIsStopped(true);
        movement = 0;
      }
    };
  }, [speed, isStopped]);

  useEffect(() => {
    const addScrollClass = (blockElem, classNam) => {
      for (let i = 0; i < blockElem.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = blockElem[i].getBoundingClientRect().top;
        let elementVisible = 50;

        if (elementTop < windowHeight - elementVisible) {
          blockElem[i]?.classList.add(classNam);
        } else {
          blockElem[i]?.classList.remove(classNam);
        }
      }
    };
    const srollContent = () => {
      let threeStep = document.querySelectorAll(
        ".start-guide-three-step .start-guide-text-container"
      );
      let ReplacingBrushHaed = document.querySelectorAll(
        ".replacing-brush-heads-section .start-guide-text-container"
      );
      addScrollClass(threeStep, "activeAnim");
      addScrollClass(ReplacingBrushHaed, "activeAnimReplacing");
    };

    window.addEventListener("scroll", srollContent);
  });

  useEffect(() => {
    setSelectedTopicList(topicList);
  }, []);

  const checkIsMobile = () => {
    return isXSmall || isSmall ? true : false;
  };

  const addClassToElement = () => {
    const element = document.querySelector(".replacing-brush-heads-section");
    element?.classList?.add("z-index-updater");
  };

  const removeClassFromElement = () => {
    const element = document.querySelector(".replacing-brush-heads-section");
    element?.classList?.remove("z-index-updater");
  };

  const closeModal = () => {
    removeClassFromElement();
    setIsModalOpen(false);
  };

  const openModal = () => {
    addClassToElement();
    setIsModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const closeGumBleedModal = () => {
    setIsGumBleedModalOpen(false);
  };

  const openGumBleedModal = () => {
    setIsGumBleedModalOpen(true);
  };

  const videoOnSelect = () => {
    openVideoModal();
  };

  const renderYoutubeVideo = (video) => {
    if (video) {
      return <YoutubeVideo document={{ fields: video }} />;
    } else {
    }
  };

  const handleBrushSelection = (value, topic, index) => {
    closeModal();
    setTimeout(() => {
      const sliderValue = topic?.fields?.sliderPopup?.fields;
      topicList.length > 0 &&
        topicList.forEach((listItem) => {
          listItem.fields.isSelected = false;
          if (listItem.fields.value === value) {
            listItem.fields.isSelected = !listItem.fields.isSelected;
          }
        });
      setSelectedTopicList([...topicList]);
      setSliderValue(sliderValue);
      openModal();
    });
  };

  const addRemainderClass = () => {
    setShowRemainderClasses(!showRemainderClasses);
  };

  const renderDesktopContent = () => {
    return (
      <div className="start-guide-content-container">
        <Image className="background-img" image={backgroundAsset} />
        <div className="start-guide-container">
          <div className="start-guide-text-container">
            <div class="start-guide-para">
            {title && (
              <p
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {titleImage && (
              <div className="ob-contentBlock-text-image">
                <Image image={titleImage} />
              </div>
            )}
            {subTitle && (
              <p
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}
            <div className="description-container">
              <Image image={descriptionIcon} />
              {description && (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
            <p
              className="topicHeader"
              dangerouslySetInnerHTML={{ __html: topicHeader }}
            />
            {CTAInformation &&
              CTAInformation?.alignment != StartGuideBlockConstants.bottom && (
                <>
                  {CTAInformation?.CTAGumBleedList &&
                    CTAInformation?.CTAGumBleedList.map((option) => {
                      return (
                        <a
                          className={`${option?.ctaCustomEventClass} cta-link`}
                          onClick={openGumBleedModal}
                          data-action-detail={option?.CTALabel}
                        >
                          <span>{option?.CTALabel}</span>
                        </a>
                      );
                    })}
                  {CTAInformation?.CTAList &&
                    CTAInformation?.CTAList.map((option) => {
                      return option?.isTogglePanel ? (
                        <a
                          className={`${option?.ctaCustomEventClass} cta-link`}
                          onClick={addRemainderClass}
                          data-action-detail={`${option?.CTALabel}`}
                        >
                          <span>{option?.CTALabel}</span>
                        </a>
                      ) : (
                        <a
                          className={`${option?.ctaCustomEventClass} cta-link`}
                          href={`${option?.CTALink}`}
                          target={"_blank"}
                          data-action-detail={`${option?.CTALabel}`}
                        >
                          <span>{option?.CTALabel}</span>
                        </a>
                      );
                    })}
                  {remainderCalender && (
                    <div className="select-calender">
                      <div
                        className={`calender-option-list-section ${
                          showRemainderClasses ? "d-block" : "d-none"
                        }`}
                      >
                        <button
                          className="event_close_window ob-modal-close gum-bleed-close-button"
                          onClick={addRemainderClass}
                        >
                          <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
                        </button>
                        <p class="calender-title">
                          {remainderCalender?.fields?.remainderListTitle}
                        </p>

                        {remainderCalender?.fields?.remainderList.map(
                          (option) => {
                            return (
                              <div
                                class={`${option?.fields?.ctaCustomEventClass} calender-content`}
                                onClick={() =>
                                  addRemainder(
                                    remainderCalender?.fields,
                                    option?.fields
                                  )
                                }
                                data-action-detail={`${option?.fields?.type} ${AnalyticsConstants.calender}`}
                              >
                                <span class="calender-img">
                                  <Image image={option?.fields?.icon} />
                                </span>
                                <span class="calender-option-list">
                                  {option?.fields?.type}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            {subdescription &&
            <div className="subdescription-container">
                <p
                  className="sub-description"
                  dangerouslySetInnerHTML={{ __html: subdescription }}
                />
              
            </div>
            }
            </div>
            {selectedTopicList && (
              <div className={"ob-start-guide-topic-container"}>
                {selectedTopicList.map((topic, topicIndex) => {
                  const value = topic?.fields?.value;
                  return (
                    <div
                      className={"ob-start-guide__form-list-item"}
                      key={"formLabel-" + topicIndex}
                    >
                      <button
                        className={`ob-start-guide__brush-type ${topic?.fields?.ctaCustomEventClass}`}
                        data-action-detail={topic?.fields?.label}
                        value={value}
                        onClick={() =>
                          handleBrushSelection(value, topic, topicIndex)
                        }
                      >
                        {!topic?.fields?.isSelected && (
                          <Image image={topic?.fields?.desktopIcon} />
                        )}

                        {topic?.fields?.isSelected && (
                          <Image image={topic?.fields?.selectedDesktopIcon} />
                        )}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: topic?.fields?.label,
                          }}
                        />
                        <span className="description-brush"
                        dangerouslySetInnerHTML={{
                          __html: topic?.fields?.description,
                        }}
                      />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {carouselCards && (
              <div className="start-guide-carousel-cards">
                {carouselCards.map((card, cardIndex) => {
                  return (
                    <div className="start-guide-one-card">
                      <Image image={card?.fields?.icons} />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card?.fields?.title,
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card?.fields?.description,
                        }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            )}

            {cards && renderCards()}

            {CTAInformation &&
              CTAInformation?.alignment == StartGuideBlockConstants.bottom &&
              video && (
                <>
                  {CTAInformation?.CTAList.map((option) => {
                    return (
                      <a
                        className={"cta-link event_button_click"}
                        onClick={() => videoOnSelect()}
                        data-action-detail={AnalyticsConstants.openVideoText}
                      >
                        <i className="down-arrow"></i>{" "}
                        <span className="CTA-Text">{option?.CTALabel}</span>
                      </a>
                    );
                  })}
                </>
              )}

            {
              <Modal
                isOpen={isVideoModalOpen}
                closeTimeoutMS={250}
                style={customStylesVideoModal}
                overlayClassName={"event_button_click"}
              >
                {renderYoutubeVideo(video?.fields?.video?.fields)}
                <button
                  className="event_close_window ob-modal-close post-purchase-video-close"
                  onClick={closeVideoModal}
                >
                  <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
                </button>
              </Modal>
            }

            {scrollContent && renderScrollContent()}
          </div>
          <div className="start-guide-image-container">
            {additionalAssetList && (
              <div className={"ob-start-guide-chargerIcon-container"}>
                {additionalAssetList.map((image) => (
                  <Image image={image} />
                ))}
              </div>
            )}

            {animationAsset && (
              <div className="ob-contentBlock-text-image" ref={imageRef}>
                <div className="ob-mainAsset-wrapper">
                  <img
                    src={animationAsset?.fields?.assetId}
                    className="ob-mainAsset-wrapper-img"
                    aria-hidden="false"
                    alt={animationAsset?.fields?.alternateText}
                    aria-label="banner-image"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {sliderValue && isModalOpen && (
          <div
            className={`${sliderValue?.className} slide-in-right`}
            style={customStyles}
          >
            <Image image={sliderValue?.backgroundAsset} />
            <a
              className="close-popup event_close_window"
              onClick={() => closeModal()}
            />
            <div className= 'cta-container'>
            {sliderValue?.ctaLabel && (
              <a
                className="start-guide-cta-link event_buy_now"
                href={`${sliderValue?.ctaLink}`}
                target={"_blank"}
                data-action-detail={`${sliderValue?.ctaLabel} - ${sliderValue?.title}`}
              >
                <p>{sliderValue?.ctaLabel}</p>
              </a>
            )}
            {sliderValue?.secondCtaLabel && 
                <a
                className={` start-guide-cta-link second`}
                href={`${sliderValue.secondCtaLink}`}
                target={"_blank"}
                data-action-detail={sliderValue?.secondCtaLabel}
                aria-label={sliderValue?.secondCtaLabel}
              >
                <p>{sliderValue?.secondCtaLabel}</p>
              </a>
              }
              <p
              className="title"
              dangerouslySetInnerHTML={{ __html: sliderValue?.title }}
            />
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: sliderValue?.description }}
            />
              </div>
          </div>
        )}

        {
          <Modal
            isOpen={isGumBleedModalOpen}
            closeTimeoutMS={250}
            style={customStylesGumBleedModal}
            overlayClassName={"event_button_click"}
          >
            {
              <div className="Gum-Bleed-Container">
                <Image image={popupContent?.fields?.icons} />
                <div className="text-content">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.title,
                    }}
                  ></div>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.description,
                    }}
                  ></div>
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: popupContent?.fields?.subTitle,
                    }}
                  ></div>
                </div>
              </div>
            }
            <button
              className="event_close_window ob-modal-close gum-bleed-close-button"
              onClick={closeGumBleedModal}
            >
              <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
            </button>
          </Modal>
        }
      </div>
    );
  };

  const renderMobileContent = () => {
    return (
      <div className="start-guide-content-container">
        <Image className="background-img" image={mobileBackgroundAsset} />
        <div className="start-guide-container">
          <div className="start-guide-text-container">
            {title && (
              <p
                className="title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}

            {titleImage && (
              <div className="ob-contentBlock-text-image">
                <Image image={titleImage} />
              </div>
            )}

            {subTitle && (
              <p
                className="subtitle"
                dangerouslySetInnerHTML={{ __html: subTitle }}
              />
            )}
            <div className="description-container">
              <Image image={descriptionIcon} />
              {description && (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
            <p
              className="topicHeader"
              dangerouslySetInnerHTML={{ __html: topicHeader }}
            />
            {CTAInformation && CTAInformation?.alignment != "bottom" && (
              <div className="cta-container">
                {CTAInformation?.CTAGumBleedList &&
                  CTAInformation?.CTAGumBleedList.map((option) => {
                    return (
                      <a
                        className={`${option?.ctaCustomEventClass} cta-link`}
                        onClick={openGumBleedModal}
                        data-action-detail={option?.CTALabel}
                      >
                        <span>{option?.CTALabel}</span>
                      </a>
                    );
                  })}

                {CTAInformation?.CTAList &&
                  CTAInformation?.CTAList.map((option) => {
                    return option?.isTogglePanel ? (
                      <a
                        className={`${option?.ctaCustomEventClass} cta-link`}
                        onClick={addRemainderClass}
                        data-action-detail={option?.ctaLabel}
                      >
                        <span>{option?.CTALabel}</span>
                      </a>
                    ) : (
                      <a
                        className={`${option?.ctaCustomEventClass} cta-link`}
                        href={`${option?.CTALink}`}
                        target={"_blank"}
                        data-action-detail={`${option?.CTALabel}`}
                      >
                        <span>{option?.CTALabel}</span>
                      </a>
                    );
                  })}
                {remainderCalender && (
                  <div className="select-calender">
                    <div
                      className={`calender-option-list-section ${
                        showRemainderClasses ? "d-block" : "d-none"
                      }`}
                    >
                      <button
                        className="event_close_window ob-modal-close gum-bleed-close-button"
                        onClick={addRemainderClass}
                      >
                        <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
                      </button>
                      <p class="calender-title">
                        {remainderCalender?.fields?.remainderListTitle}
                      </p>

                      {remainderCalender?.fields?.remainderList.map(
                        (option) => {
                          return (
                            <div
                              class={`${option?.fields?.ctaCustomEventClass} calender-content`}
                              onClick={() =>
                                addRemainder(
                                  remainderCalender?.fields,
                                  option?.fields
                                )
                              }
                              data-action-detail={`${option?.fields?.type} ${AnalyticsConstants.calender}`}
                            >
                              <span class="calender-img">
                                <Image image={option?.fields?.icon} />
                              </span>
                              <span class="calender-option-list">
                                {option?.fields?.type}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {subdescription &&
            <div className="subdescription-container">
                <p
                  className="sub-description"
                  dangerouslySetInnerHTML={{ __html: subdescription }}
                />
              
            </div>
            }
            {selectedTopicList && (
              <div className={"ob-start-guide-topic-container"}>
                {selectedTopicList.map((topic, topicIndex) => {
                  const value = topic?.fields?.value;
                  return (
                    <div
                      className={"ob-start-guide__form-list-item"}
                      key={"formLabel-" + topicIndex}
                    >
                      <button
                        className={`ob-start-guide__brush-type ${topic?.fields?.ctaCustomEventClass}`}
                        data-action-detail={topic?.fields?.label}
                        value={value}
                        onClick={() =>
                          handleBrushSelection(value, topic, topicIndex)
                        }
                      >
                        <Image
                          image={
                            !topic?.fields?.isSelected
                              ? topic?.fields?.mobileIcon
                              : topic?.fields?.selectedMobileIcon
                          }
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: topic?.fields?.label,
                          }}
                        />
                       <span className="description-brush"
                        dangerouslySetInnerHTML={{
                          __html: topic?.fields?.description,
                        }}
                      />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="mobile-carousel-container">
              {carouselCards && (
                <Slider
                  {...carouselSettings}
                  className="start-guide-carousel-cards-mobile"
                >
                  {carouselCards.map((card, cardIndex) => {
                    return (
                      <div className="start-guide-one-card">
                        <Image image={card?.fields?.icons} />
                        {card?.fields?.title && (
                          <div
                            className="card-title"
                            dangerouslySetInnerHTML={{
                              __html: card?.fields?.title,
                            }}
                          ></div>
                        )}
                        {card?.fields?.description && (
                          <div
                            className="card-description"
                            dangerouslySetInnerHTML={{
                              __html: card?.fields?.description,
                            }}
                          ></div>
                        )}
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>

          <div className="start-guide-image-container">
            {additionalAssetList && (
              <div className={"ob-start-guide-chargerIcon-container"}>
                {additionalAssetList.map((image) => (
                  <Image image={image} />
                ))}
              </div>
            )}

            {mobileAnimationAsset && (
              <div className="ob-contentBlock-text-image" ref={imageRef}>
                <div className="ob-mainAsset-wrapper">
                  <img
                    src={mobileAnimationAsset?.fields?.assetId}
                    className="ob-mainAsset-wrapper-img"
                    aria-hidden="false"
                    alt={animationAsset?.fields?.alternateText}
                    aria-label="banner-image"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {sliderValue && isModalOpen && (
          <Transition
            in={isModalOpen}
            timeout={5000}
            classNames="slide-in-bottom"
          >
            {(state) => (
              <div
                className={`${sliderValue?.className} slide-in-bottom-${state}`}
                style={customStyles}
              >
                <Image
                  className="slide-in-background"
                  image={sliderValue?.mobileBackgroundAsset}
                />
                <a
                  className="close-popup event_close_window"
                  onClick={() => closeModal()}
                />
                <div className= 'cta-container'>
            {sliderValue?.ctaLabel && (
              <a
                className="start-guide-cta-link event_buy_now"
                href={`${sliderValue?.ctaLink}`}
                target={"_blank"}
                data-action-detail={`${sliderValue?.ctaLabel} - ${sliderValue?.title}`}
              >
                <p>{sliderValue?.ctaLabel}</p>
              </a>
            )}
            {sliderValue?.secondCtaLabel && 
                <a
                className={` start-guide-cta-link second`}
                href={`${sliderValue.secondCtaLink}`}
                target={"_blank"}
                data-action-detail={sliderValue?.secondCtaLabel}
                aria-label={sliderValue?.secondCtaLabel}
              >
                <p>{sliderValue?.secondCtaLabel}</p>
              </a>
              }
              <p
              className="title"
              dangerouslySetInnerHTML={{ __html: sliderValue?.title }}
            />
            <p
              className="description"
              dangerouslySetInnerHTML={{ __html: sliderValue?.description }}
            />
              </div>
              </div>
            )}
          </Transition>
        )}

        {cards && renderCards()}

        {CTAInformation && CTAInformation?.alignment == "bottom" && video && (
          <>
            {CTAInformation?.CTAList.map((option) => {
              return (
                <a
                  className={"cta-link event_button_click"}
                  onClick={() => videoOnSelect()}
                  data-action-detail={AnalyticsConstants.openVideoText}
                >
                  <i className="down-arrow"></i>{" "}
                  <span className="CTA-Text">{option?.CTALabel}</span>
                </a>
              );
            })}
          </>
        )}

        {
          <Modal
            isOpen={isVideoModalOpen}
            closeTimeoutMS={250}
            style={customStylesVideoModal}
            overlayClassName={"event_button_click"}
          >
            {renderYoutubeVideo(video?.fields?.video?.fields)}
            <button
              className="event_close_window ob-modal-close post-purchase-video-close"
              onClick={closeVideoModal}
            >
              <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
            </button>
          </Modal>
        }

        {
          <Modal
            isOpen={isGumBleedModalOpen}
            closeTimeoutMS={250}
            style={customStylesGumBleedModal}
            overlayClassName={"event_button_click"}
          >
            {
              <div className="Gum-Bleed-Container-mobile">
                <span
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: popupContent?.fields?.title,
                  }}
                ></span>
                <span
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: popupContent?.fields?.description,
                  }}
                ></span>

                <Image image={popupContent?.fields?.mobileIcon} />
                <p
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: popupContent?.fields?.subTitle,
                  }}
                ></p>
              </div>
            }
            <button
              className="event_close_window ob-modal-close gum-bleed-close-button"
              onClick={closeGumBleedModal}
            >
              <Icon name={Constants.close} size="2.4" color="#FFFFFF" />
            </button>
          </Modal>
        }

        {scrollContent && renderScrollContent()}
      </div>
    );
  };

  const renderCards = () => {
    return (
      <div className="start-guide-content-cards">
        {cards.map((cardOption) => {
          const option = cardOption?.fields;
          return (
            <div className="cards">
              <div>
                {!checkIsMobile() && <Image image={option?.icons} />}
                {checkIsMobile() && <Image image={option?.mobileIcon} />}
              </div>
              <div className="cards-text-container">
                {option?.title && (
                  <h2 dangerouslySetInnerHTML={{ __html: option?.title }} className="card-title" />
                )}
                {option?.description && (
                  <p
                    dangerouslySetInnerHTML={{ __html: option?.description }} className="card-description"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleClick = () => {
    const element = document.getElementById(`ready-to-brush-section`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderScrollContent = () => {
    return (
      <div>
        {scrollContent.map((scrollOption) => {
          const option = scrollOption?.fields;
          return (
            <div className={option.className} onClick={() => handleClick()}>
              {option?.title && <h2>{option?.title}</h2>}
              {option?.icon && <Image image={option?.icon} />}
            </div>
          );
        })}
      </div>
    );
  };

  const handleImageHover = ( index ) => {
    if ( index < 4 ) {
      setCurrentHoverIndex( index )
    }
  }

  const handleImageHoverExit = () => {
    setCurrentHoverIndex( 10 );
  } 

  const renderMobileWelcomeBlock = () =>{
    return (
      <div
        className=
          'mobile-welcome-block'
      >
        <Image
          className={'io-background-img'}
          image={welcomeBlock?.fields?.defaultMobileBg}
        />
        <div className="welcome-text">
          <div className="welcome-title"> <span class='title-bold' dangerouslySetInnerHTML={{ __html: welcomeBlock?.fields?.mainTitle }}/></div>
          <div className="welcome-description"><span dangerouslySetInnerHTML={{ __html: welcomeBlock?.fields?.description }}/></div>
        </div>
      </div>
    );
  }
  const renderDesktopWelcomeBlock = () => {
    return (
      <div
        className={
          currentHoverIndex != 10
            ? `welcome-container-${currentHoverIndex}`
            : 'welcome-container'
        }
      >
        <Image
          className={'io-background-img'}
          image={
              welcomeBlock?.fields?.mainAssets
          }
        />
        <div className='welcome-brush-container'>
                <Image className={`brush-image}`} image={welcomeBlock?.fields?.mainAssets?.item} />
        </div>
        <div className="shadow-below-image"/>
        <div className="welcome-text">
          <div className="welcome-title"><span class='title-bold' dangerouslySetInnerHTML={{ __html: welcomeBlock?.fields?.mainTitle }}/></div>
          <div className="welcome-description"><span dangerouslySetInnerHTML={{ __html: welcomeBlock?.fields?.description }}/></div>
        </div>
      </div>     
    );
  };

  return (
    <Transition in={props.index === undefined} timeout={duration}>
      {() => (
        <section
          className={className ? className : ""}
          id={anchorId ? anchorId : ""}
        >
         {
          welcomeBlock && <>
          { checkIsMobile() ? renderMobileWelcomeBlock() : renderDesktopWelcomeBlock()}
          </>
         }
          {!welcomeBlock && <>
          {checkIsMobile() ? renderMobileContent() : renderDesktopContent()}
          </>}
        </section>
      )}
    </Transition>
  );
};

export default mediaQueryHOC(StartGuideContentBlock);