import React, { useEffect } from 'react';

const BeforeAfterImage = ( props ) => {

  const afterImageUrl = props?.afterImage?.fields?.desktopImage?.fields?.asset?.fields?.file?.url;
  const beforeImageUrl = props?.beforeImage?.fields?.desktopImage?.fields?.asset?.fields?.file?.url;

  const initSlider = () => {
    const slider = document.querySelectorAll('.beforeAfterGlider');
    const before = document.querySelectorAll('.beforeImageContainer');
    const beforeImage = document.getElementsByClassName('beforeAfterImgSelect');
    const afterimage = document.getElementsByClassName('beforeAfterImgSelectSecond')
    const gliderSlider = document.querySelectorAll('.gliderSlider');
    const nextButton = document.querySelector(
      '.beforeAfterContainer .slick-next'
    );
    const prevButton = document.querySelector(
      '.beforeAfterContainer .slick-prev'
    );
    const slickDots = document.querySelectorAll(
      '.beforeAfterContainer .slick-dots button'
    );
    let active = false;
    let changevariable;

    resetToInitial();

    document.addEventListener('DOMContentLoaded', function() {
      resetToInitial();
    });

    window.addEventListener('resize', function() {
      resetToInitial();
    });
    slickDots.forEach((element) => {
      element.addEventListener('click', function() {
        gliderSliderLeftReset();
        ResetWidthonButon();
      });
    });

    btnOpr(nextButton, prevButton);

    gliderSlider.forEach((element) => {
      element.addEventListener('mousedown', function() {
        active = true;
        element?.classList?.add('resize');
      });
    });

    document.body.addEventListener('mouseup', function() {
      active = false;
      gliderResizeRemove();
    });

    document.body.addEventListener('mouseleave', function() {
      active = false;
      gliderResizeRemove();
    });

    document.body.addEventListener('mousemove', function(e) {
      if (!active) return;
      let x = e.pageX;
      for (let z = 0; z < slider.length; z++) {
        x = e.pageX;
        x -= slider[z].getBoundingClientRect().left;
        slideIt(x, z);
        pauseEvent(e);
      }
    });

    gliderSlider.forEach((element) => {
      element.addEventListener('touchstart', function() {
        active = true;
        element?.classList?.add('resize');
      });
    });

    document.body.addEventListener('touchend', function() {
      active = false;
      gliderResizeRemove();
    });

    document.body.addEventListener('touchcancel', function() {
      active = false;
      gliderResizeRemove();
    });

    document.body.addEventListener('touchmove', function(e) {
      if (!active) return;
      touchMoceEvent(e);
      for (let z = 0; z < slider.length; z++) {
        touchMoceEvent(e);
        changevariable -= slider[z].getBoundingClientRect().left;
        slideIt(changevariable, z);
        pauseEvent(e);
      }
    });

    function slideIt(x, z) {
      let transform = Math.max(0, Math.min(x, slider[z].offsetWidth));
      before[z].style.width = transform + 'px';
      gliderSlider[z].style.left = transform - 0 + 'px';
    }
    function gliderResizeRemove() {
      gliderSlider.forEach((element) => {
        element?.classList?.remove('resize');
      });
    }
    function gliderSliderLeftReset() {
      gliderSlider.forEach((element) => {
        element.style.left = '50%';
      });
    }
    function resetToInitial() {
      for (let p = 0; p < slider.length; p++) {
        let width = slider[p].offsetWidth;
          beforeImage[p].style.width = width + 'px';
          afterimage[p].style.width = width + 'px';
      }
    }
    function touchMoceEvent(e) {
      for (let ehangedTouches of e.changedTouches) {
        changevariable = ehangedTouches.pageX;
      }
    }
    function ResetWidthonButon() {
      for (let beforeValue of before) {
        beforeValue.style.width = '50%';
      }
    }

    function pauseEvent(e) {
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    }
  };

  function btnOpr(nextButton, prevButton) {
    if (nextButton) {
      nextButton.addEventListener('click', function() {
        gliderSliderLeftReset();
        ResetWidthonButon();
      });
    }
    if (prevButton) {
      prevButton.addEventListener('click', function() {
        gliderSliderLeftReset();
        ResetWidthonButon();
      });
    }
  }

  useEffect(() => {
    initSlider();
  }, []);

  return (
    <section class='beforeAfterContainer' data-list-name='BeforeAfterSlick'>
      <div class='beforeAfterGlider'>
        <div class='beforeImageContainer'>
          <img
            class='beforeAfterImg beforeAfterImgSelect'
            src={beforeImageUrl}
            alt='Before'
          />
        </div>
        <div class='afterImageContainer'>
          <img
            class='beforeAfterImg beforeAfterImgSelectSecond'
            src={afterImageUrl}
            alt='After'
          />
        </div>
        <div class='gliderSlider'>
          <span class='arrowLeft'></span>
          <span class='arrowRight'></span>
        </div>
      </div>
    </section>
  );
};

export default BeforeAfterImage;